/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { resolveImage, resolveCB, resolveAboveCta } from "@dataResolvers";
import moment from "moment";
import { t } from "@utils";
import { Article as PageTemplate } from "@pageTemplates";
import QueryContainer from "@templates/QueryContainer";

moment.locale("en");

export const query = graphql`
  query CaseStudyQuery($uri: [String], $siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "caseStudies", uri: $uri, siteId: $siteId) {
        ... on Craft_caseStudies_caseStudy_Entry {
          title
          slug
          url
          postDate
          lang: language
          #Meta
          metaDescription
          metaFeaturedImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          postDate
          articleCategory {
            ... on Craft_blog_Category {
              title
            }
          }
          articleAuthor {
            ... on Craft_author_default_Entry {
              title
            }
          }
          # Related articles
          relatedArticles {
            ... on Craft_caseStudies_caseStudy_Entry {
              title
              heroTitle
              url
              metaDescription
              metaFeaturedImage(withTransforms: ["aboveFooterCtaExtraLarge"]) {
                width(transform: "aboveFooterCtaExtraLarge")
                height(transform: "aboveFooterCtaExtraLarge")
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url(transform: "aboveFooterCtaExtraLarge")
              }
              postDate
              articleCategory {
                ... on Craft_blog_Category {
                  title
                }
              }
              articleAuthor {
                ... on Craft_author_default_Entry {
                  title
                }
              }
            }
          }
          # Content Builder
          contentBuilder {
            ...ContentBuilderFragment
          }
          next(section: "caseStudies") {
            ... on Craft_caseStudies_caseStudy_Entry {
              title
              url
              metaDescription
              metaFeaturedImage(withTransforms: ["aboveFooterCtaExtraLarge"]) {
                width(transform: "aboveFooterCtaExtraLarge")
                height(transform: "aboveFooterCtaExtraLarge")
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url(transform: "aboveFooterCtaExtraLarge")
              }
            }
          }
        }
      }
      index: entry(section: "caseStudiesIndex", siteId: $siteId) {
        title
        ... on Craft_caseStudiesIndex_caseStudiesIndex_Entry {
          heroTitle
          entrySubtitle
          aboveFooterCtaTitle
          aboveFooterCtaSubtitle
          url
          #Meta
          metaDescription
          metaFeaturedImage(withTransforms: ["aboveFooterCtaExtraLarge"]) {
            width(transform: "aboveFooterCtaExtraLarge")
            height(transform: "aboveFooterCtaExtraLarge")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "aboveFooterCtaExtraLarge")
          }
        }
      }
      entries(section: "caseStudies", limit: 1, siteId: $siteId) {
        title
        ... on Craft_caseStudies_caseStudy_Entry {
          title
          url
          metaDescription
          metaFeaturedImage(withTransforms: ["aboveFooterCtaExtraLarge"]) {
            width(transform: "aboveFooterCtaExtraLarge")
            height(transform: "aboveFooterCtaExtraLarge")
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url(transform: "aboveFooterCtaExtraLarge")
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries, index }) => {
  if (!entry) return null;
  const {
    title,
    heroTitle,
    entrySubtitle,
    slug,
    url,
    metaFeaturedImage,
    metaDescription,
    contentBuilder,
    lang,
    next,
    postDate,
    articleCategory,
    articleAuthor,
    relatedArticles,
  } = entry;
  const firstEntry = entries?.map(e => {
    return {
      ...e,
      subtitle: e?.title,
      title: t("Next Article", lang),
    };
  })[0];
  const nextEntry = {
    ...next,
    subtitle: next?.title,
    title: t("Next Article", lang),
  };

  // Gets the right and left cards to traverse like pages
  const left = {
    title: index?.aboveFooterCtaTitle || index?.title,
    subtitle: index?.aboveFooterCtaSubtitle || index?.entrySubtitle,
    url: index?.url,
    metaFeaturedImage: index?.metaFeaturedImage,
  };
  const right = next ? nextEntry : firstEntry;

  const cards = [left, right];
  // return data
  return {
    title,
    hero: {
      title: heroTitle || title,
      subtitle: entrySubtitle,
      image: resolveImage(metaFeaturedImage),
      category: articleCategory?.[0]?.title,
      postDate: moment(postDate).format("l"),
      author: articleAuthor?.[0]?.title,
    },
    slug,
    url,
    sections: resolveCB(contentBuilder),
    relatedArticles: relatedArticles?.map(article => {
      return {
        heading: article?.heroTitle || article?.title,
        image: resolveImage(article?.metaFeaturedImage),
        url: article?.url,
        author: article?.articleAuthor?.[0]?.title,
        category: article?.articleCategory?.[0]?.title,
        postDate: moment(article?.postDate).format("l"),
        paddingSides: false,
        fullWidthMobile: true,
        fullWidthImage: true,
        width: "full",
        swapAuthorAndDate: true,
      };
    }),
    cards: resolveAboveCta(cards),
    meta: {
      title,
      metaFeaturedImage: resolveImage(metaFeaturedImage),
      metaDescription,
    },
    blog: true,
  };
};

const Page = ({ data, pageContext }) => (
  <QueryContainer
    data={data}
    pageContext={pageContext}
    PageTemplate={PageTemplate}
    dataResolver={dataResolver}
  />
);

export default Page;
